import * as React from "react"
import Layout from "hoc/Layout"
import Button from "components/Buttons/Button"
import { Grid } from "@mui/material"
import Font56 from "components/Fonts/Font56"
import Font32 from "components/Fonts/Font32"
import Font24 from "components/Fonts/Font24"

const NotFoundPage = () => (
  <Layout seoTitle="404: Nie znaleziono">
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      py={10}
    >
      <Font56 style={{ fontSize: "78px" }}>404</Font56>
      <Font32 mb={4}>Ups... Strona której szukasz nie istnieje!</Font32>
      <Font24 fw={400} mb={4}>
        Wróć na stronę główną
      </Font24>
      <Button to="/">Strona główna</Button>
    </Grid>
  </Layout>
)

export default NotFoundPage
